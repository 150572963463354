import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {p, prettyScroll} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {SliderGallery, SliderGalleryIcon} from "@pg-design/slider-gallery";
import {Text} from "@pg-design/text-module";
import {GalleryPicturesGrid} from "@pg-mono/gallery";
import {compact} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {IPropertyDetailOfferMainImage} from "../../../property/types/IPropertyDetailOfferMainImage";
import {IPropertyGallery} from "../../../property/types/IPropertyGallery";
import {getTrackedSiteData} from "../../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {IOfferGallery} from "../../detail/types/IOfferGallery";
import {hitClickOnGalleryClose, hitClickOnGalleryImage, hitClickOnGalleryNext, hitClickOnGalleryPrev, hitGalleryView} from "../../tracking/gallery_events";
import {IOfferDetailMainImage} from "../../types/IOfferDetailMainImage";

const presaleOfferDefaultImg375x212 = require("../../images/presale_offer_default_img_375x212.jpg");
const presaleOfferDefaultImg = require("../../images/presale_offer_default_img.jpg");

interface IProps {
    offer: {
        id: number;
        name: string;
        vendor: {
            id: number;
        };
        main_image?: IOfferDetailMainImage | IPropertyDetailOfferMainImage | null;
    };
    propertyGallery?: IPropertyGallery;
    offerGallery: IOfferGallery;
}

export interface IGalleryOffer {
    main_image?: IOfferDetailMainImage | IPropertyDetailOfferMainImage | null;
}

export interface IGalleryProperty {
    gallery: IPropertyGallery;
}

export type IGallery = (
    | {
          image: {
              g_img_500: string;
              g_img_750: string;
              g_img_1500: string;
          } | null;
      }
    | undefined
)[];

/*
    Helper
 */
const getGalleryImagesData = (gallery: IGallery) => {
    return gallery
        .map((item) => {
            const srcSm = item && item.image?.g_img_500;
            const srcMd = item && item.image?.g_img_750;
            const srcLg = item && item.image?.g_img_1500;

            return item ? {srcSm, srcMd, srcLg} : null;
        })
        .filter(Boolean);
};

const getGalleryImages = (offer: IGalleryOffer, offerGallery: IOfferGallery, propertyGallery?: IPropertyGallery) => {
    const mainImage = offer.main_image?.m_img_500
        ? {srcSm: offer.main_image?.m_img_500, srcMd: offer.main_image?.m_img_750, srcLg: offer.main_image?.m_img_1500}
        : {srcSm: presaleOfferDefaultImg375x212, srcMd: presaleOfferDefaultImg, srcLg: presaleOfferDefaultImg};
    const offerGalleryImages = getGalleryImagesData(offerGallery);
    const propertyGalleryImages = (propertyGallery && getGalleryImagesData(propertyGallery)) || [];
    return [...propertyGalleryImages, mainImage, ...offerGalleryImages];
};

export const OfferGalleryModalContent = (props: IProps) => {
    const {offer, offerGallery, propertyGallery} = props;
    const theme = useTheme();
    const viewType = useSelector((state: IRPStore) => state.viewType);
    const {site, site_id} = getTrackedSiteData();
    const galleryImages = compact(getGalleryImages(offer, offerGallery, propertyGallery));

    const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);
    const [galleryCounter, setGalleryCounter] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (viewType.current) {
            hitGalleryView({
                view_type: viewType.current,
                site,
                site_id,
                offer: offer.id
            });
        }
    }, [viewType.current]);

    useEffect(() => {
        setGalleryCounter(initialGalleryIndex);
    }, [initialGalleryIndex]);

    const trackingSharedData = {
        view_type: viewType.current ? viewType.current : "",
        site,
        site_id,
        offer: offer.id
    };

    const onClose = () => {
        setInitialGalleryIndex(0);
        setIsModalOpen(false);

        hitClickOnGalleryClose(trackingSharedData);
    };

    const offerGalleryLength = offerGallery.length;
    const propertyGalleryLength = propertyGallery ? propertyGallery.length : 0;

    const galleryLength = offerGalleryLength + propertyGalleryLength;

    return (
        <GalleryModalHolder>
            <GalleryPicturesGrid
                getImageAlt={(index) => `zdjęcie ${index + 1} w inwestycji ${offer.name}`}
                images={galleryImages}
                onImageClick={(index) => {
                    setIsModalOpen(true);
                    setInitialGalleryIndex(index);

                    hitClickOnGalleryImage({
                        ...trackingSharedData,
                        image_position: index
                    });
                }}
            />
            <Modal fullScreen isOpen={isModalOpen} modalTheme="dark" onModalClose={onClose}>
                <Modal.CloseButton placement="right" fillColor="#fff" />
                <Modal.Content>
                    <div css={gallerySliderHolder}>
                        <SliderGallery
                            slides={galleryImages.map((image, index) => {
                                return (
                                    <picture key={index}>
                                        <source media="(min-width: 750px)" srcSet={image?.srcLg} />
                                        <source media="(min-width: 480px)" srcSet={image?.srcMd} />
                                        <img src={image?.srcSm} alt={offer.name} />
                                    </picture>
                                );
                            })}
                            arrows={{
                                left: <SliderGalleryIcon position="left" wrapperColor={theme.colors.primary} />,
                                right: <SliderGalleryIcon position="right" wrapperColor={theme.colors.primary} />
                            }}
                            initialSlide={initialGalleryIndex}
                            onSlideChange={(current: number, next: number) => {
                                setGalleryCounter(next);

                                const trackingData = {
                                    ...trackingSharedData,
                                    image_position: next
                                };

                                if (current < next) {
                                    hitClickOnGalleryNext(trackingData);
                                }

                                if (current > next) {
                                    hitClickOnGalleryPrev(trackingData);
                                }
                            }}
                        />

                        <div css={counterHolder}>
                            <Text variant="info_txt_1" color="#fff">
                                Zdjęcie: {galleryCounter + 1} z {galleryLength + 1}
                            </Text>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        </GalleryModalHolder>
    );
};

const GalleryModalHolder = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${prettyScroll("#686975", "#838595")};
        flex-direction: row;
    }
`;

const gallerySliderHolder = css`
    position: relative;
    width: 100%;
    height: 100%;
`;

const counterHolder = css`
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translate(-50%, 0%);
    ${p(1, 2)};
    background-color: rgba(67, 68, 77, 0.7);
`;
